<template>
  <div class="page">
    <div class="c-page o-page-wrapper">
      <Sidebar :status="'consumer'" :elementClass="'.c-suggestion'"></Sidebar>
      <div class="o-sidebar-content">
        <div class="c-page__content c-page__content_no-bottom">
          <Content></Content>
          <TradeNow :outsideContent='true' :personal='true' v-if="!token"></TradeNow>
          <LikeSuggestion></LikeSuggestion>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import Content from '@/components/consumer/GettingStartedContent.vue'
import LikeSuggestion from '@/components/LikeSuggestion.vue'
import TradeNow from '@/components/TradeNow.vue'

export default {
  name: 'GettingStartedConsumer',
  components: {
    Sidebar,
    Footer,
    Content,
    LikeSuggestion,
    TradeNow
  },
  data() {
    return {
      theme: 'two',
    }
  },
  mounted: function () {
    this.updateTitleMeta('Sparrow | Start Trading BTC and ETH Options in Three Simple Steps')
    this.updateDescriptionMeta('Trade cryptocurrency options and swap digital assets with no hidden fees on Sparrow — an trading platform that is secure, compliant, and simple to use.')
    this.updateKeywordMeta('Sparow, sparow exchange, ptions tradings platform, cryptocurrencies and options trading, BTC and ETH options')
  },
  computed: {
    token: function () {
      return this.$store.getters.token;
    },
  }
}
</script>
