<template>
  <div class="c-getting-started">
    <div class="c-getting-started__title">
      <h2 class="c-getting-started__title-subtitle">Start using</h2>
      <h1 class="c-getting-started__title-text">Sparrow’s Options Trading Platform here</h1>
    </div>
    <div class="c-getting-started__description">
      <p class="c-getting-started__description-text">Begin your cryptocurrency and options trading activities on Sparrow
        platform with TradePROTECT, TradeBOOST and the ConvertNOW Facility. Simply register your account with Sparrow in
        three simple steps to get started.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GettingStartedConsumer',

  methods: {
  },

}
</script>
